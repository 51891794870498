<template>
  <div class="tc-measure">
    <!-- 排序 -->
    <div class="sort">
      <el-divider direction="vertical"></el-divider>
      <el-button type="plain" plain @click="showDialog(1)"
        >添加量表分类</el-button
      >
      <el-divider direction="vertical"></el-divider>
      <el-button type="plain" plain @click="upSort">排序</el-button>
    </div>
    <!-- 表格 -->
    <div class="content">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        stripe
        border
        class="tableBox"
      >
        <el-table-column
          type="selection"
          width="60"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="classify_name"
          label="量表类型"
          width="350"
          align="left"
          header-align="center"
        ></el-table-column>
        <el-table-column
          prop="measure_count"
          label="量表统计(份)"
          align="center"
        ></el-table-column>
        <el-table-column prop="sort" label="量表排序" align="center">
          <template slot-scope="scope">
            <!-- <el-input v-bind="scope.row.sort" /> -->
            <el-input
              v-model="scope.row.sort"
              size="mini"
              maxlength="2"
              @blur="submitSort(scope.row)"
              onkeyup="value=value.replace(/[^\d]/g,'') "
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="plain" @click="edit(scope.row)"
              >编辑</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button size="mini" type="plain" @click="remove(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 页码 -->
    <div class="page">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="1"
        :page-size="15"
        layout="total, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <el-dialog
      title="量表分类"
      :close-on-click-modal="false"
      :visible.sync="dialog.dialogVisible"
      width="30%"
    >
      <div>
        <el-form
          :model="dialog.form"
          status-icon
          :rules="dialog.rules"
          ref="ruleForm"
        >
          <el-form-item
            label="量表类型："
            label-width="100px"
            prop="measure_name"
          >
            <el-input
              v-model="dialog.form.measure_name"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog.dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitDialog">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getMeasureClassifyList,
  delMeasureClassifyList,
  addMeasureClassifyList,
  updateMeasureClassifyList,
} from "@/api/evaluate.js";
export default {
  inject: ["reload"],
  data() {
    return {
      multipleSelection: [],
      tableData: [
        {
          id: 42,
          measure_title: "心理健康临床症状自评量表(SCL-90) aaa",
          classify_id: 1,
          sort: 0,
          custom_name: "测试测试测试",
          custom_answer_desc: "测试",
          status: 1,
          classify: {
            id: 1,
            classify_name: "测试分类",
          },
        },
      ],
      order: "sort desc",
      // 页码
      page: 1,
      size: 15,
      // 总数
      total: 0,
      dialog: {
        state: 1, // 1 添加 2 编辑
        dialogVisible: false,
        form: {
          id: null,
          measure_name: "",
        },
        rules: {
          measure_name: [
            { required: true, message: "请输入分类名称！", trigger: "blur" },
          ],
        },
      },
    };
  },
  created() {
    this.loadMeasureListData();
  },
  methods: {
    // 全选
    handleSelectionChange(val) {
      this.multipleSelection = val;
      let p = [];
      this.multipleSelection.forEach((v) => {
        p.push(v.id);
      });
      this.multipleSelection = p;
    },
    handleSizeChange(val) {
      //console.log(`每页 ${val} 条`);
    },
    // 页码监听
    handleCurrentChange(val) {
      // 根据page加载对应数据
      this.page = val;
      console.log(val);

      this.loadMeasureListData();
    },
    // 刷新
    refresh() {
      this.reload();
    },
    inputNum(val) {},
    // 加载列表
    loadMeasureListData() {
      let data = {
        page: this.page,
        size: this.size,
        order: this.order,
      };
      getMeasureClassifyList(data).then((res) => {
        this.tableData = res.data.data;
        this.total = res.data.total;
      });
    },
    edit(row) {
      this.dialog.form.id = row.id;
      this.dialog.form.measure_name = row.classify_name;
      this.dialog.dialogVisible = true;
      this.dialog.state = 2;
    },
    remove(id) {
      this.$confirm("确认删除该分类吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 删除一个分类数据
          delMeasureClassifyList({ id }).then(async (res) => {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.loadMeasureListData();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    showDialog(state) {
      // 换出弹出层
      this.dialog.state = state;
      this.dialog.dialogVisible = true;
      this.dialog.form.measure_name = null;
    },
    submitDialog() {
      let that = this;
      // 不论是新增 还是编辑  都应该验证这里
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.dialog.state === 1) {
            // // add
            let param = {
              classifyName: this.dialog.form.measure_name,
            };
            addMeasureClassifyList(param).then(async (res) => {
              if (res === 400200)
                that.$message({
                  message: res.msg,
                  type: "success",
                });
              that.loadMeasureListData();
              that.dialog.dialogVisible = false;
            });
          } else {
            // edit
            let param = {
              id: this.dialog.form.id,
              classify_name: this.dialog.form.measure_name,
            };
            this.updateApi(param);
          }
        } else {
          return false;
        }
      });
    },
    submitSort(row) {
      //console.log("row", row);
      let param = {
        id: row.id,
        sort: row.sort,
      };
      // //console.log('param', param)
      this.updateApi(param);
    },
    updateApi(param) {
      updateMeasureClassifyList(param).then(async (res) => {
        if (res.code === 400200) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.loadMeasureListData();
          this.dialog.dialogVisible = false;
        }
      });
    },
    // 数据排序
    upSort() {
      if (this.order == "sort desc") {
        this.order = "sort";
      } else {
        this.order = "sort desc";
      }
      this.loadMeasureListData();
    },
  },
  filters: {
    statusFilter(val) {
      return val == 1 ? "启用" : "关闭";
    },
  },
};
</script>
<style lang="less">
.tc-measure {
  width: 100%;
  min-width: 1300px;
  // 头部
  .header {
    width: 90%;
    margin: 0 auto;
    display: flex;
    padding-top: 20px;
    .header-operation {
      ul {
        display: flex;
        li {
          //  float: left;
          width: 180px;
          list-style: none;
          padding: 0 30px;
          text-align: center;
          img {
            width: 100%;
            // pointer-events: none;
          }
        }
      }
    }
    .header-tool {
      margin-left: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      .del {
        width: 120px;
        cursor: pointer;
      }
      .refresh {
        width: 120px;
        cursor: pointer;
      }
      img {
        width: 100%;
        pointer-events: none;
      }
    }
  }
  //   排序
  .sort {
    margin-top: 20px;
    height: 55px;
    background-color: rgb(#d9f2f7);
    display: flex;
    align-items: center;
    padding-left: 10px;
    .el-divider--vertical {
      height: 2em;
    }
    .el-button {
      background: transparent;
      color: rgb(#044d5a);
      font-size: 15px;
      border: 1px solid rgb(182, 226, 235);
      &:hover {
        border: 1px solid rgb(105, 105, 105);
      }
    }
  }
  //   表格
  .content {
    position: relative;
    height: calc(~"100% - 220px");
    min-height: 600px;
    margin-top: 20px;
    color: rgb(4, 87, 102);
    th {
      color: rgb(4, 87, 102);
      padding: 0;
      height: 40px;
      line-height: 40px;
      background: rgb(217, 242, 247);
    }
    td {
      padding: 0;
      height: 35px;
      line-height: 35px;
    }
    tr.el-table__row--striped td {
      background: rgb(234, 246, 248);
    }
    .el-button + .el-button {
      margin-left: 0px;
    }
    .el-button--mini {
      padding: 4px 4px;
      background: transparent;
      color: rgb(4, 87, 102);
      font-size: 14px;
      &:hover {
        border: 1px solid rgb(105, 105, 105);
      }
    }
    .no {
      color: green;
    }
    .off {
      color: red;
    }
    .el-input__inner {
      width: 50px;
      padding: 5px;
      text-align: center;
    }
  }
  .page {
    // .el-pagination {
    //   position: absolute;
    //   bottom: 0;
    //   right: 0;
    // }
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
}
</style>